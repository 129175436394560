<template>
  <div>
    <div>
      <h1 class="white--text text-center ma-5">{{ $i18n.translate("Track My Rebate") }}</h1>
    </div>
    <v-container class="fill-height">
      <v-row justify="center">
        <v-col lg="6" md="8" sm="10" xs="12">
          <v-card>
            <v-card-title class="eur-card-subtitle">
              {{ $i18n.translate("Lookup your Rebate using the forms below") }}
            </v-card-title>
            <v-card-text class="mt-5">
              <ApiError :errors="errors"></ApiError>
              <div class="text-center" v-if="loading">
                <v-progress-circular indeterminate color="primary"></v-progress-circular>
              </div>
              <div v-else>
                <v-container v-if="showSearch">
                  <v-row>
                    <v-col xs="12" sm="12" md="6" lg="6">
                      <v-card>
                        <v-card-subtitle class="eur-card-title">
                          {{ $i18n.translate("By Email") }} &amp; {{ $i18n.translate("Postal Code") }}
                        </v-card-subtitle>
                        <v-card-text>
                          <v-form @submit.stop.prevent="onSubmitEmailAndPostalCode" v-model="valid1">
                            <v-text-field
                              v-model="claimForm.email"
                              :rules="rules.email"
                              :label="$i18n.translate('Email')"
                            ></v-text-field>
                            <v-text-field
                              v-model="claimForm.postalCode"
                              :rules="rules.postalCode"
                              :label="$i18n.translate('Postal Code')"
                            ></v-text-field>
                          </v-form>
                        </v-card-text>
                        <v-card-actions>
                          <v-btn
                            block
                            class="primary"
                            :disabled="!valid1"
                            :loading="loading"
                            @click="onSubmitEmailAndPostalCode"
                            >{{ $i18n.translate("Search") }}</v-btn
                          ><br />
                        </v-card-actions>
                      </v-card>
                    </v-col>
                    <v-col xs="12" sm="12" md="6" lg="6">
                      <v-card>
                        <v-card-subtitle class="eur-card-title">
                          {{ $i18n.translate("By Claim Number") }}
                        </v-card-subtitle>
                        <v-card-text>
                          <v-form @submit.stop.prevent="onSubmitClaimNumber" v-model="valid2">
                            <v-text-field
                              v-model="claimForm.claimKey"
                              :rules="rules.claimNumber"
                              :label="$i18n.translate('Claim Number')"
                            ></v-text-field>
                          </v-form>
                        </v-card-text>
                        <v-card-actions>
                          <v-btn
                            block
                            class="primary"
                            :disabled="!valid2"
                            :loading="loading"
                            @click="onSubmitClaimNumber"
                            >{{ $i18n.translate("Search") }}</v-btn
                          >
                        </v-card-actions>
                      </v-card>
                    </v-col>
                  </v-row>
                  <v-row><GoogleReCaptcha></GoogleReCaptcha></v-row>
                </v-container>
                <div v-else>
                  <p class="mb-10">
                    {{ claims.length }} {{ $i18n.translate("Rebate") }}
                    <span>{{ claims.length == 1 ? "Claim" : "Claims" }}</span> {{ $i18n.translate("Found") }}
                    <v-btn color="primary" class="float-right" @click="showSearch = true">{{
                      $i18n.translate("Search Again")
                    }}</v-btn>
                  </p>
                  <v-card v-for="(claim, i) in claims" :key="i" class="mb-5">
                    <v-card-title class="headline eur-card-title">
                      {{ claim.promotion.name }}
                    </v-card-title>
                    <v-card-subtitle class="eur-card-subtitle">
                      {{ $i18n.translate("Claim Number") }} {{ claim.claimKey }}
                    </v-card-subtitle>
                    <v-card-text>
                      <v-container>
                        <v-row>
                          <v-col cols="6">
                            <v-text-field
                              :label="$i18n.translate('Claim #')"
                              v-model="claim.claimKey"
                              outlined
                              readonly
                              hide-details
                            ></v-text-field>
                          </v-col>
                          <v-col cols="6">
                            <v-text-field
                              :value="claim.submittedDate | formatDateClient('MM/DD/YYYY', selectedClient)"
                              :label="$i18n.translate('Submission Date')"
                              readonly
                              outlined
                              hide-details
                            ></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="6">
                            <v-text-field
                              :label="$i18n.translate('Claim Status')"
                              :value="$util.formatStage(claim.claimStage, claim)"
                              readonly
                              outlined
                              hide-details
                            ></v-text-field>
                          </v-col>
                          <v-col cols="6" v-if="claim.calcultedCalculatedTotalClaimAmount">
                            <v-text-field
                              :label="$i18n.translate('Claim Amount')"
                              :value="
                                (claim.calcultedCalculatedTotalClaimAmount
                                  ? claim.calcultedCalculatedTotalClaimAmount | toNumber(2, selectedProgram)
                                  : '') +
                                  (' ' +
                                    (claim.calculatedCalculatedTotalClaimAmountCurrency
                                      ? claim.calculatedCalculatedTotalClaimAmountCurrency
                                      : ''))
                              "
                              readonly
                              outlined
                              hide-details
                            ></v-text-field
                          ></v-col>
                        </v-row>
                        <v-row
                          v-if="
                            claim &&
                              claim.promotion &&
                              claim.promotion.forcedAwardVehicle &&
                              claim.promotion.forcedAwardVehicle.name
                          "
                        >
                          <v-col cols="12">
                            <v-textarea outlined label="Payouts" v-model="claim.claimProductsPayoutsText"></v-textarea>
                            <v-text-field
                              v-if="!claim.claimProducts"
                              :label="$i18n.translate('Award Vehicle')"
                              :value="
                                claim &&
                                claim.promotion &&
                                claim.promotion.forcedAwardVehicle &&
                                claim.promotion.forcedAwardVehicle.name
                                  ? claim.promotion.forcedAwardVehicle.name
                                  : ''
                              "
                              readonly
                              outlined
                              hide-details
                            ></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12">
                            <v-btn block class="primary" :loading="loading" @click="emailFullDetails(claim)">{{
                              $i18n.translate("Email Full Details of my Claim")
                            }}</v-btn>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>
                  </v-card>
                </div>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import GoogleReCaptcha from "../../gapp-components/components/display/GoogleReCaptcha.vue";
import ApiError from "../../gapp-components/components/display/ApiError.vue";
import { mapGetters } from "vuex";

export default {
  components: { ApiError, GoogleReCaptcha },
  name: "CeurTrack",
  metaInfo() {
    return {
      title: this.$i18n.translate("Track My Rebate")
    };
  },
  data() {
    return {
      showSearch: true,
      loading: false,
      search: "",
      claims: [],
      claimForm: {},
      errors: {},
      valid1: false,
      valid2: false,
      rules: {
        email: [v => (!!v && v.trim().length > 0) || "Email is required"],
        postalCode: [v => (!!v && v.trim().length > 0) || "Postal Code is required"],
        claimNumber: [v => (!!v && v.trim().length > 0) || "Claim Number is required"]
      }
    };
  },
  methods: {
    onSubmitEmailAndPostalCode() {
      this.claimForm.claimKey = "";
      this.onSubmit();
    },
    onSubmitClaimNumber() {
      this.claimForm.email = "";
      this.claimForm.postalCode = "";
      this.onSubmit();
    },
    onClear() {
      this.claimForm = {};
    },
    onSubmit() {
      this.errors = {};
      this.loading = true;
      this.showSearch = false;
      this.$api
        .postWithCaptcha("/api/claims/search/public", this.claimForm, null, "eurTrack")
        .then(({ data }) => {
          this.claims = data.content;
          for (let claim of this.claims) {
            this.$api.get("/api/claimProducts/byClaimId/" + claim.id + "/public").then(({ data }) => {
              claim.claimProducts = data.content;
              let amounts = [];
              let products = [];
              for (let claimProduct of claim.claimProducts) {
                for (let payout of claimProduct.payouts) {
                  if (payout.awardVehicle && payout.awardVehicle.awardVehicleType.name != "GENERIC_PRODUCT") {
                    let awardVehicleName = payout.awardVehicle.name;
                    if (!amounts[awardVehicleName]) {
                      amounts[awardVehicleName] = 0;
                    }
                    amounts[awardVehicleName] += payout.payoutAmount;
                  } else {
                    if (
                      payout.payoutStoreCatalogProduct &&
                      payout.payoutStoreCatalogProduct.storeProduct &&
                      payout.payoutStoreCatalogProduct.storeProduct.productNumber
                    ) {
                      if (!products[payout.payoutStoreCatalogProduct.storeProduct.productNumber]) {
                        products[payout.payoutStoreCatalogProduct.storeProduct.productNumber] = 0;
                      } else
                        products[payout.payoutStoreCatalogProduct.storeProduct.productNumber] +=
                          payout.payoutProductQuantity;
                    }
                  }
                }
              }
              claim.claimProductsPayoutsText = "";
              for (let [key, value] of Object.entries(amounts)) {
                claim.claimProductsPayoutsText += key + ": " + this.$util.toCurrency(value) + "\n";
              }
              if (Object.entries(products).length > 0) {
                claim.claimProductsPayoutsText += "\nProducts: \n";
              }

              for (let [key, value] of Object.entries(products)) {
                claim.claimProductsPayoutsText += key + ": " + value + " units \n";
              }
            });
          }
          this.loading = false;
          this.onClear();
        })
        .catch(e => {
          this.loading = false;
          this.errors = this.$api.getErrorsFromResponse(e);
        });
    },

    emailFullDetails(claim) {
      this.loading = true;
      this.$api
        .postWithCaptcha("/api/claims/email/public", { claimId: claim.id, emailTemplateKey: "CLAIM_FULL_DETAILS" })
        .then(() => {
          this.loading = false;
        })
        .catch(e => {
          this.errors = this.$api.getErrorsFromResponse(e);
          this.loading = false;
        });
    }
  },
  computed: {
    ...mapGetters(["selectedProgram", "selectedClient", "selectedLocale"])
  }
};
</script>
